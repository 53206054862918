import React, { Component } from 'react';
import logo from "../images/logoSimple.png"
import { version } from "../../package.json";
let cssLoaded = false;
class TitleBar extends Component {
  render() {
    if(cssLoaded === false){
      cssLoaded = true;
      // eslint-disable-next-line no-unused-expressions
      import('./TitleBar.css');
    }
    return (
      <header id="titlebar" style={{MozUserSelect: "none", WebkiteUserSelect: "non", msUserSelect:"none"}}>
        <div id="drag-region">
          <div id="window-title">
            <img src={logo} alt="ZaltyBet" style={{maxHeight: "100%"}}/>
          </div>
          <div id="window-version">
            {version}
          </div>
          <div id="window-controls">
            <div className="button" id="min-button">
              <span>&#xE921;</span>
            </div>
            <div className="button" id="max-button">
              <span>&#xE922;</span>
            </div>
            <div className="button" id="restore-button">
              <span>&#xE923;</span>
            </div>
            <div className="button" id="close-button">
              <span>&#xE8BB;</span>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default TitleBar;
