import React, { Component } from 'react';
import { Route, Switch } from "react-router-dom";
import { MainPage, DownloadPage, HistoryPage } from "./pages"
import { TitleBar, ClientLinkContainer } from "./components";
import {Button, ButtonGroup} from "react-bootstrap";
//import ButtonGroup from "react-bootstrap/lib/ButtonGroup";
import './App.css';
import { logo } from "./images";

class App extends Component {

  constructor(){
    super();

    global.client = window.location.href.includes("?client");
    global.clientString = window.location.href.includes("?client")?"?client":"";
  }

  render() {
    return (
      <div className={`App ${global.client?"client":""} client-unselectable`}>
        {global.client && <TitleBar />}
        <Route exact path="/client" component={TitleBar} />
        <header className="App-header">
          <img draggable="false" src={logo} alt="ZaltyBet" style={{maxWidth: "100%"}}/>
          <ButtonGroup className="App-nav">
            <ClientLinkContainer className="nav-button" activeClassName="selected" to={`/${global.clientString}`}><Button draggable="false">Home</Button></ClientLinkContainer>
            <ClientLinkContainer className="nav-button" activeClassName="selected" to={`/history${global.clientString}`}><Button draggable="false">History</Button></ClientLinkContainer>
            {!global.client && <ClientLinkContainer className="nav-button" activeClassName="selected" to={`/download${global.clientString}`}><Button draggable="false">Download Client</Button></ClientLinkContainer>}

          </ButtonGroup>
          <Switch>
            <Route path="/download" component={DownloadPage}/>
            <Route path="/history" component={HistoryPage}/>
            <Route path="/" component={MainPage}/>
          </Switch>
        </header>
      </div>
    );
  }
}

export default App;
