import React, { Component } from 'react';
import PropTypes from "prop-types";

//import Col from 'react-bootstrap/lib/Col';
//import Form from "react-bootstrap/lib/Form";
//import Button from "react-bootstrap/lib/Button";
import {InputGroup, Button, Form, Col} from 'react-bootstrap';

import './PredictionForm.css';

class PredictionForm extends Component {

  static defaultProps = {
    button: "primary",
  }

  static propTypes = {
    button: PropTypes.oneOf([
      "primary", "secondary", "success", "warning", "danger", "info", "light", "dark", "link",
      "outline-primary", "outline-secondary", "outline-success", "outline-warning", "outline-danger", "outline-info", "outline-light", "outline-dark"
    ]).isRequired,
    style: PropTypes.object,
  };

  constructor(){
    super();

    this.red = React.createRef();
    this.blue = React.createRef();

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.formatPrediction = this.formatPrediction.bind(this);
    this.checkForm = this.checkForm.bind(this);
    this.checkFighters = this.checkFighters.bind(this);

    this.state = {
      validated : false,
      rater: 0,
      red: "",
      blue: "",
    };
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value , validated:false});
  }

  checkForm(){
    return (this.state.red !== "" && this.state.blue !== "");
  }

  checkFighters(){
    const redValid = this.state.prediction && !this.state.prediction.prediction.uncertain["1"],
    blueValid = this.state.prediction && !this.state.prediction.prediction.uncertain["2"]
    if(redValid){
      this.red.current.setCustomValidity("");
    }else{
      this.red.current.setCustomValidity("Invalid");
    }
    if(blueValid){
      this.blue.current.setCustomValidity("");
    }else{
      this.blue.current.setCustomValidity("Invalid");
    }
    return redValid && blueValid;
  }

  handleSubmit(event){
    event.preventDefault();
    if (this.checkForm() === false) {
      event.stopPropagation();
      this.setState({ validated: true });
    }else{
      fetch(`https://zaltybet.zeboot.coffee/api/${this.state.rater}/prediction/${this.state.red}/${this.state.blue}`)
      .then(res => res.json())
      .then(prediction => {
        this.setState({prediction: prediction});
        if(!this.checkFighters()){
          this.setState({validated: true});
        }
      });
    }

  }

  formatPrediction(){
    const pred = this.state.prediction;
    const prob = (100*pred.prediction.probability).toFixed(2);
    return (
      <h2>
        {!(pred.prediction.uncertain["1"] || pred.prediction.uncertain["2"]) && (
          <>
          Prediction: {' '}
          { prob > 52
            ? (pred.prediction.fighter)
            : (<>Prediction: Equal odds</>)
          }
           {' '}({prob}%)
          </>
        )}
        {(pred.prediction.uncertain["1"] || pred.prediction.uncertain["2"]) && <span>Unknown fighter(s) specified.</span>}
        <br/>
        <small><small className="text-muted">{pred.red} vs. {pred.blue}</small></small>
      </h2>
    );
  }

  render() {
    const { button, style, ...others } = this.props;
    return (
      <div>
        <h1>Predict a Match</h1>
        <Form
          noValidate
          inline
          validated = {this.state.validated}
          onSubmit = {this.handleSubmit}
          style={{...style}}
          {...others}
        >
          <Form.Row style={{margin:"auto"}}>
            <Form.Group>
              <Col>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text className="alert-danger">Red</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    placeholder="Red Fighter"
                    type="text"
                    name="red"
                    ref={this.red}
                    value={this.state.red}
                    onChange={this.handleChange}
                    required
                  />
                </InputGroup>
              </Col>
              <Button id="predictButton" variant={button} type="submit" tabIndex="-1">Predict</Button>
              <Col>
                <InputGroup>
                  <Form.Control
                    placeholder="Blue Fighter"
                    type="text"
                    name="blue"
                    ref={this.blue}
                    value={this.state.blue}
                    onChange={this.handleChange}
                    required
                  />
                  <InputGroup.Append>
                    <InputGroup.Text className="alert-info">Blue</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </Col>
            </Form.Group>
          </Form.Row>
        </Form>
        {this.state.prediction && this.formatPrediction()}
      </div>
    );
  }
}

export default PredictionForm;
