import { LinkContainer } from "react-router-bootstrap";
import React from 'react';
export const ClientLinkContainer = props => (
  <LinkContainer isActive={(_, { pathname }) => {
    let path = pathname.split("index.html");
    let prunedTo = props.to.split("?")[0];
    let path2 = prunedTo.split("/");
    return pathname === prunedTo || path[path.length-1] === path2[path2.length-1]}} {...props} />
)
export default ClientLinkContainer;
