import React, { Component } from 'react';
import { Button } from "react-bootstrap";
import { FaWindows } from "react-icons/fa";
import { version } from "../../package.json";
class DownloadPage extends Component {

  componentDidMount(){
    document.title = "ZaltyBet Client Downloads";
  }

  render(){
    return(
      <div className="download-mount">
        <h2>ZaltyBet Client</h2>
        <h3>Here you can download the latest ZaltyBet client for the listed platforms.</h3>
        <div>
          <Button href="/client/windows"><FaWindows /> Windows Installer ({version})</Button>
        </div>
      </div>
    );
  };

}

export default DownloadPage;
