import React, { Component } from 'react';
import PropTypes from "prop-types";

import { Chart, Doughnut} from "react-chartjs-2";

class Accuracy extends Component {

  static defaultProps = {
    size: 30,
    refresh: 30,
    border: "#007595",
    color: "#61dbfb"
  }

  static propTypes = {
    rater: PropTypes.number,
    limit: PropTypes.number,
    size: PropTypes.number.isRequired,
    refresh: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired,
    border: PropTypes.string.isRequired,
    style: PropTypes.object,
  };

  constructor() {
    super();
    this.getAccuracy = this.getAccuracy.bind(this);

    this.state = {
      total: 1,
      correct: 0,
      refresh: undefined
    }
  }

  componentDidMount(){
    Chart.pluginService.register({
      beforeDraw: function (chart) {
        if (chart.config.options.elements.center) {
          //Get ctx from string
          var ctx = chart.chart.ctx;

          //Get options from the center object in options
          var centerConfig = chart.config.options.elements.center;
          var fontStyle = centerConfig.fontStyle || 'Arial';
          var txt = centerConfig.text;
          var color = centerConfig.color || '#000';
          var sidePadding = centerConfig.sidePadding || 20;
          var sidePaddingCalculated = (sidePadding/100) * (chart.innerRadius * 2)
          //Start with a base font of 30px
          ctx.font = "30px " + fontStyle;

          //Get the width of the string and also the width of the element minus 10 to give it 5px side padding
          var stringWidth = ctx.measureText(txt).width;
          var elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;

          // Find out how much the font can grow in width.
          var widthRatio = elementWidth / stringWidth;
          var newFontSize = Math.floor(30 * widthRatio);
          var elementHeight = (chart.innerRadius * 2);

          // Pick a new font size so it will not be larger than the height of label.
          var fontSizeToUse = Math.min(newFontSize, elementHeight);

          //Set font settings to draw it correctly.
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          var centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
          var centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
          ctx.font = fontSizeToUse+"px " + fontStyle;
          ctx.fillStyle = color;

          //Draw text in center
          ctx.fillText(txt, centerX, centerY);
        }
      }
    });
    if(this.props.refresh > 0){
      var refreshInterval = setInterval(this.getAccuracy, this.props.refresh*1000);
      this.setState({refresh: refreshInterval});
    }

    this.getAccuracy();
  }

  componentWillUnmount(){
    if(this.state.refresh) clearInterval(this.state.refresh);
  }

  getAccuracy(){
    fetch("https://zaltybet.zeboot.coffee/api/"+this.props.rater+ "/stats/"+(this.props.limit?this.props.limit:""))
    .then(res => res.json())
    .then(stats => {
      this.setState({total: stats.total, correct: stats.correct});
    });
  }

  render() {
      const { limit, size, style, color, border, ...others } = this.props;
      let data = {
      labels: ["Correct", "Incorrect"],
      datasets: [{
        data: [this.state.correct, this.state.total - this.state.correct],
        backgroundColor: [
          color,
        ],
        borderColor: [
          border,
          border
        ]
      }]
    };

    let options = {
      maintainAspectRatio: false,
      cutoutPercentage: 90,
      legend: {
        display: false
      },
      tooltips: {
        enabled: false
      },
      hover: {mode:null},
      elements: {
        center: {
          text: (100*this.state.correct / this.state.total).toFixed(2) + "%",
          color: color
        }
      }
    };


    return (
      <div className="Accuracy" style={{...style}} {...others}>
        <div style={{height: size+"vmin", width: size+"vmin", margin: "0 auto 0 auto"}}>
          <Doughnut data={data} options={options} style={{margin: "auto"}}/>
        </div>
        <p>{limit?<>Current</>:<>Overall</>} Prediction Accuracy<br/><small><small className="text-muted">{this.state.correct} out of {limit && <>the last</>} {this.state.total} matches predicted correctly.</small></small></p>
      </div>
    );
  }
}

export default Accuracy;
