import React, { Component } from 'react';

import { Accuracy, PredictionForm } from "../components";

class MainPage extends Component {
  componentDidMount(){
    document.title = "ZaltyBet";
  }

  render(){
    return(<div className="main-mount">
            <div>
              <Accuracy rater={0} style={{display: "inline-block", marginRight: "1vmin"}}/>
              <Accuracy rater={1} style={{display: "inline-block", marginLeft: "1vmin"}}/>
            </div>
            <PredictionForm button="outline-info" />
            </div>
          );
  }
}

export default MainPage;
