import React, { Component } from 'react';
import { History } from "../components";

class HistoryPage extends Component {

  componentDidMount(){
    document.title = "ZaltyBet Accuracy History";
  }

  render(){
    return(<div className="history-mount">
    <h2>ZaltyBet Prediction Accuracy History</h2>
      <History />
      </div>
    );
  };

}

export default HistoryPage;
