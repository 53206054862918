import React, { Component } from 'react';
import PropTypes from "prop-types";
import { Chart, Line } from "react-chartjs-2";
import zoom from 'chartjs-plugin-zoom';
import moment from 'moment';

class History extends Component {

  static defaultProps = {
    width: 70,
    height: 40,
    refresh: 5*60,
    border: "#007595",
    color: "#61dbfb"
  }

  static propTypes = {
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    refresh: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired,
    border: PropTypes.string.isRequired,
    style: PropTypes.object,
  };

  constructor() {
    super();
    this.getHistory = this.getHistory.bind(this);

    this.state = {
      history: []
    };

  }

  componentDidMount(){
    Chart.plugins.register(zoom);
    Chart.plugins.register({
       afterDraw: function(chart) {
          if (chart.data.labels.length === 0) {
             // No data is present
             var ctx = chart.chart.ctx;
             var width = chart.chart.width;
             var height = chart.chart.height
             chart.clear();

             ctx.save();
             ctx.textAlign = 'center';
             ctx.textBaseline = 'middle';
             ctx.font = "20px normal 'Helvetica Neue'";
             ctx.fillText('No data to display', width / 2, height / 2);
             ctx.restore();
           }
         }
    });
    //Chart.plugins.register(zoom);
    if(this.props.refresh > 0){
      var refreshInterval = setInterval(this.getHistory, this.props.refresh*1000);
      this.setState({refresh: refreshInterval});
    }
    this.getHistory();
  }

  componentWillUnmount(){
    if(this.state.refresh) clearInterval(this.state.refresh);
  }

  getHistory(){
    fetch("https://zaltybet.zeboot.coffee/api/0/stats/history")
    .then(res => res.json())
    .then(history => {
      this.setState({history: history});
    });
  }


  render(){
    const { height, width, style, color, border, ...others } = this.props;

    const dates = this.state.history.map(entry => moment(entry.day));
    let data = {
    labels: dates,
    datasets: [{
      label: "Daily Accuracy",
      data: this.state.history.map(entry => (100*entry.correct/entry.total).toFixed(2)),
      borderColor: color,
      pointBackgroundColor: border
    },{
      label: "Overall Accuracy",
      data: dates.map(date => {
        let corr = this.state.history.reduce((total, x) => {
          if(moment(x.day).isSameOrBefore(date)){
            return {total: total.total + x.total, correct: total.correct + x.correct};
          }
          return total;
        }, {total: 0, correct: 0});
        return (100*corr.correct/corr.total).toFixed(2);
      }),
      borderColor: border,
      pointBackgroundColor: color
    }]
  };

  let options = {
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
        ticks: {
          callback: function(value, index, values){
            return value + "%";
          },
          fontColor: border
        }
      }],
      xAxes: [{
        type: 'time',
        time: {
          unit: 'day',
          min: (moment(dates[dates.length-1])||moment()).subtract(3, 'months'),
        },
      }]
    },
    tooltips: {
      enabled: false,
      callbacks: {
        label: function(tooltipItems, data){
          return tooltipItems.yLabel + "%";
        }
      }
    },

  };
  if(dates.length > 0){
    options = {...options, ...{
      plugins: {
        zoom: {
          pan: {
            enabled: true,
            mode: 'x',
            rangeMin: {
                x: dates[0].valueOf()
            },
            rangeMax: {
              x: dates[dates.length-1].valueOf()
            }
          },
          zoom: {
            enabled: true,
            drag: false,
            mode: 'x',
            speed: .1,
            rangeMin: {
                x: dates[0].valueOf()
            },
            rangeMax: {
              x: dates[dates.length-1].valueOf()
            }
          },
        },
      },
    }};
  }

    return(
      <div className="History" style={{...style}} {...others}>
        <div style={{height: height+"vh", width: width+"vmin", margin: "0 auto 0 auto"}}>
          <Line
            data={data}
            options={options}
            style={{margin: "auto"}}
          />
        </div>
        <p><small><small className="text-muted">Accuracy History is updated hourly.</small></small></p>
      </div>
    );
  };

}

export default History;
